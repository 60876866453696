import React, { useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useDispatch } from 'react-redux';
import { searchProductSales } from '../../../features/sales/productSalesSlice';


const PersonalInformation = (): JSX.Element => {
    const productSales = useAppSelector((state) => state.sales.products.productSales);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(searchProductSales())
    }, [dispatch])

    return (
        <div>
            <h2>Product Sales</h2>
            <TableContainer component={Paper} elevation={3}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productSales.map((productSale) => {
                            return (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    key={productSale.product + '_' + productSale.amount}
                                >
                                    <TableCell component="th" scope="row">
                                        {productSale.product}
                                    </TableCell>
                                    <TableCell align="right">{productSale.amount}</TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PersonalInformation