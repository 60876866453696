import React from 'react';
import { Grid } from '@mui/material';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router';
import DokProductView from './DokProductView/DokProductView';
import CheckoutView from './CheckoutView/CheckoutView';

const DokPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const isDokAllowed = useAppSelector((state) => state.user.userInformation?.is_dok_allowed)
    const navigate = useNavigate();

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('dok') || !isDokAllowed) {
        navigate('/');
        return <></>;
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <DokProductView />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CheckoutView />
                </Grid>
            </Grid>
        </div>
    );
};

export default DokPage