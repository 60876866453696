import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { useAppSelector } from '../../../../hooks';
import { addProductToProductCategory, getAllProductCategories, getAllProductCategoryTypes } from '../../../../features/products/ProductCategoriesSlice';

interface createProductDialogInterface {
    open: boolean,
    onClose: () => void,
    productId: number
}


const EditProductCategoryDialog = ({ open, onClose, productId }: createProductDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [productCategoryValue, setProductCategoryValue] = useState<string | null>(null);
    const [productCategoryInputValue, setProductCategoryInputValue] = useState('');
    const [productCategoryTypeValue, setProductCategoryTypeValue] = useState<string | null>(null);
    const [productCategoryTypeInputValue, setProductCategoryTypeInputValue] = useState('');

    const [productCategoryOptions, setProductCategoryOptions] = useState<string[]>([]);
    const [productCategoryTypeOptions, setProductCategoryTypeOptions] = useState<string[]>([]);

    const productCategories = useAppSelector((state) => state.productCategories.product_categories)
    const productCategoryTypes = useAppSelector((state) => state.productCategories.product_categorie_types)
    useEffect(() => {
        dispatch(getAllProductCategories());
        dispatch(getAllProductCategoryTypes());
    }, [dispatch]);

    useEffect(() => {
        const newOptions = productCategories.map((productCategory) => {
            return productCategory.name;
        });
        setProductCategoryOptions(newOptions);
    }, [productCategories])

    useEffect(() => {
        const newOptions = productCategoryTypes.map((productCategoryType) => {
            return productCategoryType.name;
        });
        setProductCategoryTypeOptions(newOptions);
    }, [productCategoryTypes])

    const onChangeProductCategory = (() => {
        const productCategory = productCategories.find((productCategory) => productCategory.name === productCategoryValue);
        const productCategoryType = productCategoryTypes.find((productCategoryType) => productCategoryType.name === productCategoryTypeValue);
        if (!productCategory || !productCategoryType) {
            return;
        }
        dispatch(addProductToProductCategory(productId, productCategory.id, productCategoryType.id));
        onClose();
    });
    console.log(productCategoryTypeInputValue)
    console.log(productCategoryTypeValue)
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Product</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Autocomplete
                    sx={{marginTop: '10px'}}
                    value={productCategoryValue}
                    onChange={(event: any, newValue: string | null) => {
                        setProductCategoryValue(newValue);
                    }}
                    inputValue={productCategoryInputValue}
                    onInputChange={(event, newInputValue) => {
                        setProductCategoryInputValue(newInputValue);
                    }}
                    options={productCategoryOptions}
                    renderInput={(params) => <TextField {...params} label="Category" />}
                />
                <Autocomplete
                    sx={{marginTop: '10px'}}
                    value={productCategoryTypeValue}
                    onChange={(event: any, newValue: string | null) => {
                        setProductCategoryTypeValue(newValue);
                    }}
                    inputValue={productCategoryTypeInputValue}
                    onInputChange={(event, newInputValue) => {
                        setProductCategoryTypeInputValue(newInputValue);
                    }}
                    options={productCategoryTypeOptions}
                    renderInput={(params) => <TextField {...params} label="Category type" />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onChangeProductCategory}>Edit Category</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditProductCategoryDialog;