import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

// Define a type for the slice state
interface PaymentMethodState {
    paymentMethods: PaymentMethod[],
}

export interface PaymentMethod {
    id: number,
    name: string
}

// Define the initial state using that type
const initialState: PaymentMethodState = {
    paymentMethods: []
}

export const paymentMethodSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setPaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
            state.paymentMethods = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setPaymentMethods } = paymentMethodSlice.actions
export default paymentMethodSlice.reducer

export const searchPaymentMethods = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/payment_methods')
        .then((response: AxiosResponse<{ status: string, data: PaymentMethod[] }>) => {
            dispatch(setPaymentMethods(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}