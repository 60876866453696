import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router';
import { Alert } from '@mui/material';
import { createUser, setCreatedUserId, setCreateUserError } from '../../../features/users/UsersSlice';

interface createUserDialogInterface {
    open: boolean,
    onClose: () => void,
}

interface CreateProductFormData {
    email: string | null,
    password: string | null,
    name: string | null
}

const CreateUserDialog = ({ open, onClose }: createUserDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string | null>(null);
    const createdUserId = useAppSelector((state) => state.users.createdUserId)
    const createUserError = useAppSelector((state) => state.users.createUserError)
    const navigate = useNavigate();
    const [createUserFormData, setCreateUserFormData] = useState<CreateProductFormData>({
        email: null,
        password: null,
        name: null
    });

    const onCreateRole = (() => {
        if (createUserFormData.email && createUserFormData.password && createUserFormData.name) {
            dispatch(createUser(createUserFormData.name, createUserFormData.email, createUserFormData.password))
        } else {
            setError('please enter a email, name and a password.')
        }
    });

    useEffect(() => {
        if (createdUserId) {
            dispatch(setCreatedUserId(null));
            navigate('/users/' + createdUserId)
        }
    }, [createdUserId, dispatch, navigate])

    useEffect(() => {
        if (createUserError) {
            dispatch(setCreateUserError(null));
            setError(createUserError);
        }
    }, [createUserError, dispatch, navigate])

    const onUserNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCreateUserFormData({
            ...createUserFormData,
            name: event.target.value
        });
    };

    const onUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCreateUserFormData({
            ...createUserFormData,
            email: event.target.value
        });
    };

    const onUserPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCreateUserFormData({
            ...createUserFormData,
            password: event.target.value
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create User</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <TextField
                    sx={{ mt: '10px' }}
                    label="Name"
                    value={createUserFormData.name || ''}
                    onChange={onUserNameChange}
                    fullWidth
                />
                <TextField
                    sx={{ mt: '10px' }}
                    label="Email"
                    value={createUserFormData.email || ''}
                    onChange={onUserEmailChange}
                    fullWidth
                />
                <TextField
                    sx={{ marginTop: '10px' }}
                    label="Password"
                    value={createUserFormData.password || ''}
                    type="password"
                    onChange={onUserPasswordChange}
                    fullWidth
                />
                {error &&
                    <Alert sx={{ marginTop: '10px' }} severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onCreateRole}>Create User</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateUserDialog;