import React from 'react';
import { useAppSelector } from '../../../hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatPrice } from '../../../helpers/formatters/formatPrice';

const DokExportModule = (): JSX.Element => {
    const user = useAppSelector((state) => state.users.user);

    return (
        <div>
            <h2>
                Dok export
            </h2>
            {user && (
                <TableContainer component={Paper} elevation={3}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Value</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Object.keys(user?.dok_export || {}).map((category: string) => {
                                return (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {category}
                                        </TableCell>
                                        <TableCell align="right">{formatPrice(user.dok_export[category])}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default DokExportModule;