import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getIndividualUserInformation } from '../../features/users/UsersSlice';
import PersonalInformationModule from './PersonalInformationModule/PersonalInformationModule';
import RightsModule from './RightsModule/RightsModule';
import OrdersModule from './OrdersModule/OrdersModule';
import DokExportModule from './DokExportModule/DokExportModule';


const UserDetailPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const authenticatedUserId = useAppSelector((state) => state.user.userInformation?.id);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userId } = useParams<"userId">();

    useEffect(() => {
        if (authenticated && userId) {
            dispatch(getIndividualUserInformation(parseInt(userId)))
        }
    }, [authenticated, dispatch, userId])

    if (!authenticated) {
        return <></>;
    } else if ((userId && !parseInt(userId)) || (!hasRight('user_search') && userId && parseInt(userId) !== authenticatedUserId)) {
        navigate('/');
        return <></>;
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <PersonalInformationModule />
                </Grid>
                <Grid item xs={12} md={6}>
                    <RightsModule />
                </Grid>
                {userId &&
                    <Grid item xs={12} md={6}>
                        <OrdersModule userId={parseInt(userId)} />
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <DokExportModule />
                </Grid>
            </Grid>
        </div>
    );
};

export default UserDetailPage