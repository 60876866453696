import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { useAppSelector } from '../../../../hooks';
import {
    setEditUserError,
    setEditUserState,
    uploadProfileImage
} from '../../../../features/users/UsersSlice';

interface createProductDialogInterface {
    open: boolean,
    onClose: () => void,
}

interface UploadProfileImageFormData {
    id: number | null,
    profileImage: File | null
}

const UploadProfileImageDialog = ({ open, onClose }: createProductDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string | null>(null);
    const individualUser = useAppSelector((state) => state.users.user);
    const editUserRequestError = useAppSelector((state) => state.users.editUserError);
    const editUserState = useAppSelector((state) => state.users.editUserState);
    const [editUserInformationFormData, setEditProductFormData] = useState<UploadProfileImageFormData>({
        id: null,
        profileImage: null
    });

    useEffect(() => {
        if (editUserState === 'success') {
            dispatch(setEditUserState('idle'));
            onClose();
        }
    }, [dispatch, editUserState, onClose])

    useEffect(() => {
        if (editUserRequestError) {
            dispatch(setEditUserError(null));
            setError(editUserRequestError);
        }
    }, [dispatch, editUserRequestError])

    useEffect(() => {
        if (individualUser) {
            setEditProductFormData({
                id: individualUser.id,
                profileImage: null
            });
        }
    }, [individualUser])

    const onEditProfileImage = (() => {
        if (
            editUserInformationFormData.id &&
            editUserInformationFormData.profileImage
        ) {
            setError(null);
            dispatch(uploadProfileImage(editUserInformationFormData.id, editUserInformationFormData.profileImage));
        } else {
            setError('Please upload a profile image.')
        }
    });

    // On file select (from the pop up)
    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Update the state
        const file = event.target.files;
        if (file && file.length > 0) {
            setEditProductFormData({...editUserInformationFormData, profileImage: (file[0] || null)});
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit User Profile Image</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <input type="file" onChange={onFileChange} />
                {error &&
                    <Alert sx={{ marginTop: '10px' }} severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onEditProfileImage}>Edit User</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadProfileImageDialog;