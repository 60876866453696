import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

// Define a type for the slice state
interface UserState {
    state: 'loading' | 'idle' | 'failed' | 'success',
    authenticated: boolean,
    userInformation: UserInformation | null
}

interface UserInformation {
    id: number,
    name: string,
    email: string,
    roles: UserInformationRole[],
    is_dok_allowed: boolean
}

export interface UserInformationRole {
    id: number,
    name: string,
    rights: UserInformationRight[]
}

export interface UserInformationRight {
    id: number,
    name: string
}

// Define the initial state using that type
const initialState: UserState = {
    state: 'idle',
    authenticated: false,
    userInformation: null
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginStarted: (state) => {
            state.state = 'loading';
        },
        loginFailed: (state) => {
            state.state = 'failed'
        },
        loginSucces: (state) => {
            state.state = 'success';
        },
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
        },
        setUserInformation: (state, action: PayloadAction<UserInformation | null>) => {
            state.userInformation = action.payload;
        }
    },
})

export const selectUserName = (state: RootState): string | null => state.user.userInformation && state.user.userInformation.name

// Action creators are generated for each case reducer function
export const { loginStarted, loginFailed, loginSucces, setUserInformation, setAuthenticated } = userSlice.actions
export default userSlice.reducer

export const loginUser = (email: string, password: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch(loginStarted());
    axios.get('/sanctum/csrf-cookie')
        .then(() => {
            axios.post('/api/auth/login', {
                email: email,
                password: password
            })
                .then(() => {
                    dispatch(loginSucces());
                    dispatch(setAuthenticated(true));
                    dispatch(getUserInformation());
                })
                .catch(() => {
                    dispatch(loginFailed());
                });
        })
}

export const getUserInformation = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    axios.get('/api/user')
        .then((response: AxiosResponse<{ data: UserInformation | null }>) => {
            if (response.data.data) {
                dispatch(setUserInformation(response.data.data));
                dispatch(setAuthenticated(true));
            } else {
                dispatch(setAuthenticated(false));
            }
        })
        .catch((e) => {
            console.log(e);
        });
}


export const logoutUser = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    axios.post('/api/auth/logout')
        .then((response: AxiosResponse<{ name: string }>) => {
            dispatch(setUserInformation(null));
            dispatch(setAuthenticated(false));
            document.location.href = '/';
        })
        .catch((e) => {
            console.log(e);
        });
}

export const verifyOauthCode = (code: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/sanctum/csrf-cookie')
        .then(() => {
            axios.post('/api/auth/oauth', { code: code })
                .then((response: AxiosResponse<{status: 'success'|'failure'}>) => {
                    if (response.data.status === 'success') {
                        dispatch(loginSucces());
                        dispatch(setAuthenticated(true));
                        dispatch(getUserInformation());
                        document.location.href = '/';
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        })
}