import React, { useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useDispatch } from 'react-redux';
import { formatPrice } from '../../../helpers/formatters/formatPrice';
import { getLatestPayments } from '../../../features/sales/paymentSalesSlice';

const PaymentsModule = (): JSX.Element => {
    const payments = useAppSelector((state) => state.sales.payments.payments);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLatestPayments())
    }, [dispatch])

    return (
        <div>
            <h2>Payments</h2>
            <TableContainer component={Paper} elevation={3}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.map((payment)=> {
                            return (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    key={payment.id}
                                >
                                    <TableCell component="th" scope="row">
                                        {payment.id}
                                    </TableCell>
                                    <TableCell>
                                        {payment.order.user.name}
                                    </TableCell>
                                    <TableCell align="right">{formatPrice(payment.amount)}</TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PaymentsModule