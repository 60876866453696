import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

// Define a type for the slice state
interface PaymentSalesState {
    payments: Payment[],
}

export interface Payment {
    id: number,
    amount: number,
    order: {user: {name: string}}
}

// Define the initial state using that type
const initialState: PaymentSalesState = {
    payments: []
}

export const paymentSalesSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setPayments: (state, action: PayloadAction<Payment[]>) => {
            state.payments = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setPayments } = paymentSalesSlice.actions
export default paymentSalesSlice.reducer

export const getLatestPayments = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/sales/payments')
        .then((response: AxiosResponse<{ status: string, data: Payment[] }>) => {
            dispatch(setPayments(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}