import React from 'react';
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router';
import { formatPrice } from '../../../helpers/formatters/formatPrice';

const ProductsSearchResultTable = (): JSX.Element => {
    const products = useAppSelector((state) => state.products.products);
    const navigate = useNavigate();

    const onIdClick = (productId: number): void => {
        navigate('/products/' + productId);
    }

    return (
        <TableContainer component={Paper} elevation={3}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product) => (
                        <TableRow
                            key={product.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {product.id}
                            </TableCell>
                            <TableCell>
                                <Link
                                    onClick={() => { onIdClick(product.id) }}
                                    underline="hover"
                                >
                                    {product.name}
                                </Link>
                            </TableCell>
                            <TableCell align="right">{formatPrice(product.price)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProductsSearchResultTable;