import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { UserOrder } from '../OrdersModule';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatPrice } from '../../../../helpers/formatters/formatPrice';

interface AddRightDialogInterface {
    onClose: () => void,
    order: UserOrder
}

const DisplayOrderDialog = ({ onClose, order }: AddRightDialogInterface): JSX.Element => {
    let totalPrice = 0;
    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>Order</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <TableContainer component={Paper} elevation={3}>
                    <Table aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Product</TableCell>
                                <TableCell align="center">Qty.</TableCell>
                                <TableCell align="right">Unit</TableCell>
                                <TableCell align="right">Sum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.order_rows.map((orderRow) => {
                                totalPrice += orderRow.quantity * orderRow.price;
                                return (
                                    <TableRow key={orderRow.id}>
                                        <TableCell>{orderRow.product.name}</TableCell>
                                        <TableCell align="right">
                                            {orderRow.quantity}
                                        </TableCell>
                                        <TableCell align="right">{formatPrice(orderRow.price)}</TableCell>
                                        <TableCell align="right">{formatPrice(orderRow.quantity * orderRow.price)}</TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow>
                                <TableCell colSpan={3}>Total</TableCell>
                                <TableCell align="right">{formatPrice(totalPrice)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer >

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DisplayOrderDialog;