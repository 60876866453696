import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import DateAdapter from '@mui/lab/AdapterLuxon';
import { LocalizationProvider } from '@mui/lab';

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <App />
                </LocalizationProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
