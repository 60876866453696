import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';
import { getIndividualProductInformation } from './ProductsSlice';

// Define a type for the slice state
interface ProductCategoryState {
    product_categories: ProductCategory[],
    product_categorie_types: ProductCategoryType[],
    createdProductCategoryId: number | null,
    createProductCategoryError: string | null
}

export interface ProductCategory {
    id: number,
    name: string
}

export interface ProductCategoryType {
    id: number,
    name: string
}

// Define the initial state using that type
const initialState: ProductCategoryState = {
    product_categories: [],
    product_categorie_types: [],
    createdProductCategoryId: null,
    createProductCategoryError: null
}

export const productCategoriesSlice = createSlice({
    name: 'productCategories',
    initialState,
    reducers: {
        setProductCategories: (state, action: PayloadAction<ProductCategory[]>) => {
            state.product_categories = action.payload;
        },
        setProductCategoryTypes: (state, action: PayloadAction<ProductCategory[]>) => {
            state.product_categorie_types = action.payload;
        },
        setCreatedProductCategoryError: (state, action: PayloadAction<string | null>) => {
            state.createProductCategoryError = action.payload;
        },
        setCreatedProductCategoryId: (state, action: PayloadAction<number | null>) => {
            state.createdProductCategoryId = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setProductCategories, setCreatedProductCategoryError, setCreatedProductCategoryId, setProductCategoryTypes } = productCategoriesSlice.actions
export default productCategoriesSlice.reducer

export const getAllProductCategories = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/product_categories')
        .then((response: AxiosResponse<{ status: string, data: ProductCategory[] }>) => {
            dispatch(setProductCategories(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}

export const getAllProductCategoryTypes = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/product_category_types')
        .then((response: AxiosResponse<{ status: string, data: ProductCategoryType[] }>) => {
            dispatch(setProductCategoryTypes(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}


export const deleteProductFormProductCategory = (productId: number, productCategoryId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.delete(`/api/products/${productId}/product_categories/${productCategoryId}`)
        .then((response: AxiosResponse) => {
            dispatch(getIndividualProductInformation(productId));
        })
        .catch((e) => {
            console.log(e);
        });
}

export const addProductToProductCategory = (productId: number, productCategoryId: number, productCategoryTypeId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/products/${productId}/product_categories/${productCategoryId}`, {
        'product_category_type_id': productCategoryTypeId
    })
        .then((response: AxiosResponse) => {
            dispatch(getIndividualProductInformation(productId));
        })
        .catch((e) => {
            console.log(e);
        });
}

interface CreateProductCategoryResponseSuccess {
    status: 'success',
    data: number
}

interface CreateProductCategoryResponseError {
    status: 'error',
    message: string
}

type CreateProductCategorySuccess = CreateProductCategoryResponseSuccess | CreateProductCategoryResponseError

export const createProductCategory = (name: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/product_category`, {
        name: name
    })
        .then((response: AxiosResponse<CreateProductCategorySuccess>) => {
            if (response.data.status === 'success' ) {
                dispatch(setCreatedProductCategoryId(response.data.data));
            } else if (response.data.status === 'error') {
                dispatch(setCreatedProductCategoryError(response.data.message))
            }
        })
        .catch((e) => {
            console.log(e);
        });
}