import React from 'react';
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router';


const RolesSearchResultsTable = (): JSX.Element => {
    const roles = useAppSelector((state) => state.roles.roles);
    const navigate = useNavigate();

    const onIdClick = (roleId: number): void => {
        navigate('/roles/' + roleId);
    }

    return (
        <TableContainer component={Paper} elevation={3}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {roles.map((role) => (
                        <TableRow
                            key={role.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" >
                                {role.id}
                            </TableCell>
                            <TableCell>
                                <Link
                                    onClick={() => { onIdClick(role.id) }}
                                    underline="hover"
                                >
                                    {role.name}
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RolesSearchResultsTable;