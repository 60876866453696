import React, { useEffect, useState } from 'react';
import LoginForm from './components/LoginForm';
import { getUserInformation, logoutUser, selectUserName } from './features/authentication/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Event from '@mui/icons-material/Event';
import { Button, Dialog } from '@mui/material';
import { useAppSelector } from './hooks';
import {Person} from '@mui/icons-material';
import ApplicationRouter from './components/ApplicationRouter/ApplicationRouter';
import { useNavigate } from "react-router-dom";
import { hasRight } from './helpers/hasRight';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EuroIcon from '@mui/icons-material/Euro';
import MoneyIcon from '@mui/icons-material/Money';
import SportsBarIcon from '@mui/icons-material/SportsBar';
const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function App() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [loginFormOpen, setLoginFormOpen] = useState(false);
    const authorized = useAppSelector(state => state.user.authenticated);
    const authorizedUserId = useAppSelector(state => state.user.userInformation?.id);
    const isDokAllowed = useAppSelector(state => state.user.userInformation?.is_dok_allowed)
    const userName = useSelector(selectUserName);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    // function to handle modal close
    const handleLoginFormClose = () => {
        setLoginFormOpen(false);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const onTimeslotsMenuClick = () => {
        navigate("/timeslots");
    }

    const onUsersMenuClick = () => {
        navigate("/users");
    }

    const onRolesMenuClick = () => {
        navigate("/roles");
    }

    const onProductsMenuClick = () => {
        navigate("/products");
    }

    const onDokMenuClick = () => {
        navigate('/dok');
    }

    const onUserClick = (): void => {
        navigate('/users/' + authorizedUserId)
    }

    const onSalesClick = (): void => {
        navigate('/sales')
    }


    const onDokExportClick = (): void => {
        navigate('/export/dok');
    }


    useEffect(() => {
        dispatch(getUserInformation())
    }, [dispatch]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <SportsBarIcon />

                    <Typography variant="h6" sx={{ flexGrow: 1, marginLeft: '8px' }} component="div">
                        Drinken Op Parlement
                    </Typography>
                    {authorized && (
                        <Button color="inherit" onClick={handleLogout} >{'Uitloggen'}</Button>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {authorized &&
                    <>
                        <List>
                            <ListItem button onClick={onUserClick}>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary={userName} />
                            </ListItem>
                        </List>
                        <Divider />
                    </>
                }
                <List>
                    {hasRight('timeslot_search') &&
                        <ListItem button onClick={onTimeslotsMenuClick}>
                            <ListItemIcon>
                                <Event />
                            </ListItemIcon>
                            <ListItemText primary={'Timeslots'} />
                        </ListItem>
                    }
                    {hasRight('dok') && isDokAllowed &&
                        <ListItem button onClick={onDokMenuClick}>
                            <ListItemIcon>
                                <EuroIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Dok'} />
                        </ListItem>
                    }
                    {hasRight('user_search') &&
                        <ListItem button onClick={onUsersMenuClick}>
                            <ListItemIcon>
                                <PeopleAltIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Users'} />
                        </ListItem>
                    }
                    {hasRight('role_search') &&
                        <ListItem button onClick={onRolesMenuClick}>
                            <ListItemIcon>
                                <PeopleAltIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Roles'} />
                        </ListItem>
                    }
                    {hasRight('product_search') &&
                        <ListItem button onClick={onProductsMenuClick}>
                            <ListItemIcon>
                                <PeopleAltIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Products'} />
                        </ListItem>
                    }
                    {hasRight('product_sales_search') &&
                        <ListItem button onClick={onSalesClick}>
                            <ListItemIcon>
                                <MoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Sales'} />
                        </ListItem>
                    }
                    {hasRight('dok_export') &&
                        <ListItem button onClick={onDokExportClick}>
                            <ListItemIcon>
                                <MoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Dok Export'} />
                        </ListItem>
                    }
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                <Dialog open={loginFormOpen} onClose={handleLoginFormClose}>
                    <LoginForm onClose={handleLoginFormClose} />
                </Dialog>
                <ApplicationRouter />
            </Main>
        </Box >
    );
}

export default App;
