import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Product, searchProducts } from '../../../features/products/ProductsSlice';
import { useAppSelector } from '../../../hooks';
import { hasRight } from '../../../helpers/hasRight';
import { formatPrice } from '../../../helpers/formatters/formatPrice';
import { addProduct } from '../../../features/products/CheckoutSlice';

interface CategorizedProducts {
    [key: string]: Product[]
}

const DokProductView = (): JSX.Element => {
    const [categorizedProductsCopy, setCategorizedProductsCopy] = useState<CategorizedProducts>({});
    const [categorizedProducts, setCategorizedProducts] = useState<CategorizedProducts>({});
    const [topFiveProducts, setTopFiveProducts] = useState<Product[]>([]);
    const [searchProductName, setSearchProductName] = useState('');
    const [selectedProductCategory, setSelectedProductCategory] = useState<string | null>(null);
    const products = useAppSelector((state) => state.products.products);
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(searchProducts({ active: '1' }));
    }, [dispatch])

    useEffect(() => {
        const newCategorizedProducts: CategorizedProducts = {};
        if (searchProductName) {
            Object.keys(categorizedProductsCopy).forEach((categoryName) => {
                newCategorizedProducts[categoryName] = categorizedProductsCopy[categoryName].filter((product) => {
                    return product.name.toLowerCase().includes(searchProductName.toLowerCase());
                })
            })
            setCategorizedProducts(newCategorizedProducts);
        } else {
            setCategorizedProducts(categorizedProductsCopy);
        }
    }, [categorizedProductsCopy, searchProductName])

    useEffect(() => {
        if (selectedProductCategory) {
            if (selectedProductCategory === 'Top 5') {
                setCategorizedProducts({'Top 5': topFiveProducts})
                return;
            }
            setCategorizedProducts({[selectedProductCategory]: categorizedProductsCopy[selectedProductCategory]})
        } else {
            setCategorizedProducts(categorizedProductsCopy)
        }
    }, [categorizedProductsCopy, selectedProductCategory, topFiveProducts])

    useEffect(() => {
        const newCategorizedProducts: CategorizedProducts = {
            'Uncategorized': []
        }
        const top5Products: Product[] = [];

        products?.forEach((product) => {
            let category = null;
            product.product_product_categories.forEach((productProductCategory) => {
                if (productProductCategory.product_category_type.name === 'display') {
                    category = productProductCategory.product_category.name;
                }
            });

            if (category) {
                if (Object.keys(newCategorizedProducts).includes(category)) {
                    newCategorizedProducts[category] = newCategorizedProducts[category].concat(product);
                } else {
                    newCategorizedProducts[category] = [product];
                }
            } else {
                newCategorizedProducts['Uncategorized'].push(product);
            }
            if (product.top_5) {
                top5Products.push(product);
            }
        })
        setTopFiveProducts(top5Products);
        setCategorizedProductsCopy(newCategorizedProducts)
        setCategorizedProducts(newCategorizedProducts);
    }, [products])

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('dok')) {
        navigate('/');
        return <></>;
    }

    const onSearchProductNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedProductCategory(null);
        setSearchProductName(event.target.value);
    };

    const getProductCard = (product: Product): JSX.Element => {
        return (
            <Grid item md={3} key={product.id}>
                <Card style={{ cursor: 'pointer' }} onClick={() => { dispatch(addProduct(product.id)) }}  elevation={3}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {product.name}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {formatPrice(product.price)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Add</Button>
                    </CardActions>
                </Card>
            </Grid>
        )
    }

    const handleCategoryButtonClick = (value: string|null) => {
        return (): void => {
            setSearchProductName('');
            setSelectedProductCategory(value);
        }
    };

    return (
        <>
            <TextField
                sx={{ marginTop: '10px' }}
                label="Product naam"
                value={searchProductName}
                onChange={onSearchProductNameChange}
                fullWidth
            />
            <ButtonGroup
                sx={{
                    marginTop: '45px',
                    width: '100%'
                }}
                variant="outlined"
                size="large"
            >
                <Button
                    onClick={handleCategoryButtonClick(null)}
                    variant={selectedProductCategory === null ? 'contained' : 'outlined'}

                >
                    Alle producten
                </Button>
                <Button
                    onClick={handleCategoryButtonClick('Top 5')}
                    variant={selectedProductCategory === 'Top 5' ? 'contained' : 'outlined'}
                >
                    Top 5
                </Button>
                {Object.keys(categorizedProductsCopy).sort().map((category) => {
                    if (categorizedProductsCopy[category].length === 0) {
                        return <></>;
                    }

                    return (
                        <Button
                            key={category}
                            onClick={handleCategoryButtonClick(category)}
                            variant={category === selectedProductCategory ? 'contained' : 'outlined'}
                        >
                            {category}
                        </Button>
                    )
                })}
            </ButtonGroup>

            {selectedProductCategory === null && searchProductName === '' && topFiveProducts.length > 0 && (
                <div>
                    <h3>Top 5</h3>
                    <Grid container spacing={3}>
                        {topFiveProducts.map((product) => {
                            return getProductCard(product);
                        })}
                    </Grid>
                </div>
            )}


            {Object.keys(categorizedProducts).sort().map((category) => {
                console.log(categorizedProducts, category);
                if (categorizedProducts[category].length === 0) {
                    return <></>;
                }

                return (
                    <div key={category}>
                        <h3>{category}</h3>
                        <Grid container spacing={3}>
                            {categorizedProducts[category].map((product) => {
                                return getProductCard(product);
                            })}
                        </Grid>
                    </div>
                )
            })}
        </>
    );
};

export default DokProductView