import { DateTimePicker } from '@mui/lab';
import { Alert, Autocomplete, Button, Grid, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAllRoles } from '../../features/roles/RolesSlice';
import { createTimeslot, CreateTimeslotData, setCreatedTimeslotId } from '../../features/timeslots/timeslotSlice';
import { useAppSelector } from '../../hooks';

const CreateTimeslotPage = (): JSX.Element => {
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [timeslotFormData, setTimeslotFormData] = useState<CreateTimeslotData>({
        name: null,
        capacity: null,
        start_time: null,
        end_time: null,
        role_id: null
    });
    const roles = useAppSelector((state) => state.roles.roles)
    const [roleOptions, setRoleOptions] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedRoleName, setSelectedRoleName] = useState<string | null>(null);

    const createdTimeslotId = useAppSelector((state) => state.timeslot.createdTimeslotId);

    useEffect(() => {
        dispatch(getAllRoles());
    }, [dispatch]);

    useEffect(() => {
        const newRoles = roles.map((role) => {
            return role.name;
        });
        setRoleOptions(newRoles);
    }, [roles]);

    if (createdTimeslotId) {
        dispatch(setCreatedTimeslotId(null));
        navigate('/timeslots/' + createdTimeslotId);
    }

    const validateFormData = (): string[] => {
        let messages: string[] = [];

        if (!timeslotFormData.name) {
            messages = messages.concat('Please enter a name.')
        }

        if (!timeslotFormData.capacity) {
            messages = messages.concat('Please enter a capacity.')
        } else if (timeslotFormData.capacity < 1) {
            messages = messages.concat('Please enter a capacity greater than 0.');
        }

        if (!timeslotFormData.start_time) {
            messages = messages.concat('Please enter a start time.')
        }

        if (!timeslotFormData.end_time) {
            messages = messages.concat('Please enter a end time.')
        }

        if (
            timeslotFormData.start_time
            && timeslotFormData.end_time
            && timeslotFormData.start_time.toMillis() > timeslotFormData.end_time.toMillis()
        ) {
            messages = messages.concat('Please enter a start time that is earlier than the end time.')
        }

        return messages;
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const messages = validateFormData();
        if (messages.length === 0) {
            const role = roles.find((role) => role.name === selectedRoleName);
            dispatch(createTimeslot({...timeslotFormData, role_id: role?.id || null}));
        }
        setErrorMessages(messages);
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeslotFormData({
            ...timeslotFormData,
            name: event.target.value
        })
    }

    const onCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeslotFormData({
            ...timeslotFormData,
            capacity: parseInt(event.target.value)
        })
    }

    const onStartTimeChamge = (newStartTime: DateTime | null) => {
        setTimeslotFormData({
            ...timeslotFormData,
            start_time: newStartTime
        })
    }

    const onEndTimeChange = (newEndTime: DateTime | null) => {
        setTimeslotFormData({
            ...timeslotFormData,
            end_time: newEndTime
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3} >
                <Grid item sm={12}>
                    <TextField
                        label="Name"
                        type="text"
                        value={timeslotFormData.name}
                        onChange={onNameChange || ''}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        label="Capacity"
                        type="number"
                        value={timeslotFormData.capacity || ''}
                        onChange={onCapacityChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Start time"
                        value={timeslotFormData.start_time}
                        onChange={onStartTimeChamge}
                    />
                </Grid>
                <Grid item sm={12}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="End Time"
                        value={timeslotFormData.end_time}
                        onChange={onEndTimeChange}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Autocomplete
                        sx={{ marginTop: '10px' }}
                        value={selectedRoleName}
                        onChange={(event: any, newValue: string | null) => {
                            setSelectedRoleName(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        options={roleOptions}
                        renderInput={(params) => <TextField {...params} label="Role" />}
                    />
                </Grid>
                <Grid item sm={12}>
                    {errorMessages.map((message: string) => {
                        return (
                            <Alert key={message} severity="error">{message}</Alert>
                        )
                    })}
                </Grid>
                <Grid item sm={12}>
                    <Button type="submit" variant="contained" color="primary">
                        Create
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateTimeslotPage;