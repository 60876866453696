import React, { useState } from 'react';
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';
import { hasRight } from '../../../helpers/hasRight';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { green, red } from '@mui/material/colors';
import { addProduct, CheckoutProduct, removeProduct } from '../../../features/products/CheckoutSlice';
import { formatPrice } from '../../../helpers/formatters/formatPrice';
import CreateorderDialog from './CreateOrderDialog/CreateOrderDialog';

const CheckoutView = (): JSX.Element => {
    const [isCreateOrderDialogOpen, setIsCreateOrderDialogOpen] = useState(false);
    const products = useAppSelector((state) => state.products.products);
    const checkoutProducts = useAppSelector((state) => state.checkout.products);
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('dok')) {
        navigate('/');
        return <></>;
    }
    let totalPrice = 0;
    checkoutProducts.forEach((checkoutProduct: CheckoutProduct) => {
        const associatedProduct = products.find((product) => product.id === checkoutProduct.productId);
        if (associatedProduct) {
            return totalPrice += associatedProduct.price * checkoutProduct.quantity;
        }
    });

    return (
        <div style={{position: 'sticky', top: '97px'}}>
            <CreateorderDialog
                open={isCreateOrderDialogOpen}
                onClose={() => setIsCreateOrderDialogOpen(false)}
            />
            <TableContainer component={Paper} elevation={3}>
                <Table aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell align="center">Qty.</TableCell>
                            <TableCell align="right">Unit</TableCell>
                            <TableCell align="right">Sum</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {checkoutProducts.map((checkoutProduct) => {
                            const associatedProduct = products.find((product) => product.id === checkoutProduct.productId);
                            if (!associatedProduct) {
                                return null;
                            }

                            return (
                                <TableRow key={checkoutProduct.productId}>
                                    <TableCell>{associatedProduct.name}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => dispatch(addProduct(checkoutProduct.productId))}>
                                            <AddIcon sx={{ color: green[500] }} />
                                        </IconButton>
                                        <Button
                                            style={{ backgroundColor: 'transparent', width: '20px' }}
                                        >
                                            {checkoutProduct.quantity}
                                        </Button>
                                        <IconButton onClick={() => dispatch(removeProduct(checkoutProduct.productId))}>
                                            <RemoveIcon sx={{ color: red[500] }} />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="right">{formatPrice(associatedProduct.price)}</TableCell>
                                    <TableCell align="right">{formatPrice(checkoutProduct.quantity * associatedProduct.price)}</TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell colSpan={3}>Total</TableCell>
                            <TableCell align="right">{formatPrice(totalPrice)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {checkoutProducts.length > 0 &&
                    <Button onClick={() => setIsCreateOrderDialogOpen(true)} variant="contained" sx={{ mt: 2, px: 5, py: 2 }}>
                        Bestelling aanmaken
                    </Button>
                }
            </Box>
        </div>
    );
};

export default CheckoutView