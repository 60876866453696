import React from 'react';
import { useAppSelector } from '../../../hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const RoleInformationModule = (): JSX.Element => {
    const role = useAppSelector((state) => state.roles.role);

    return (
        <div>
            <h2>Role information</h2>
            <TableContainer component={Paper} elevation={3}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Id
                            </TableCell>
                            <TableCell align="right">{role?.id}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Name
                            </TableCell>
                            <TableCell align="right">{role?.name}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default RoleInformationModule