import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface ConfirmDialogInterface {
    open: boolean,
    title?: string,
    body: string
    handleClose: () => void,
    handleSucces: () => void
}

const ConfirmDialog = ({ open, title, body, handleClose, handleSucces }: ConfirmDialogInterface): JSX.Element => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title &&
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            }
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    No
                </Button>
                <Button onClick={handleSucces} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;