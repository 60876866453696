import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

// Define a type for the slice state
interface RoleState {
    roles: Role[],
    role: IndividualRole | null,
    createdRoleId: number | null,
    createRoleError: string | null
}

export interface IndividualRole {
    id: number,
    name: string,
    rights: RoleRight[]
}

interface RoleRight {
    id: number,
    name: string
}

export interface SearchRolesParams {
    id: number | null
    name: string | null,
}

export interface Role {
    id: number,
    name: string
}

// Define the initial state using that type
const initialState: RoleState = {
    roles: [],
    role: null,
    createRoleError: null,
    createdRoleId: null
}

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setRoles: (state, action: PayloadAction<Role[]>) => {
            state.roles = action.payload;
        },
        setRole: (state, action: PayloadAction<IndividualRole>) => {
            state.role = action.payload;
        },
        setCreateRoleError: (state, action: PayloadAction<string | null>) => {
            state.createRoleError = action.payload;
        },
        setCreatedRoleId: (state, action: PayloadAction<number | null>) => {
            state.createdRoleId = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setRoles, setRole, setCreateRoleError, setCreatedRoleId } = rolesSlice.actions
export default rolesSlice.reducer

export const getAllRoles = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/roles')
        .then((response: AxiosResponse<{ status: string, data: Role[] }>) => {
            dispatch(setRoles(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}

export const searchRoles = (params: SearchRolesParams): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/roles', {
        params: params
    })
        .then((response: AxiosResponse<{ status: string, data: Role[] }>) => {
            dispatch(setRoles(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}

export const getIndividualRoleInformation = (roleId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/roles/' + roleId)
        .then((response: AxiosResponse<{ status: string, data: IndividualRole }>) => {
            dispatch(setRole(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}

export const deleteRightFromRole = (roleId: number, rightId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.delete(`/api/roles/${roleId}/rights/${rightId}`)
        .then((response: AxiosResponse) => {
            dispatch(getIndividualRoleInformation(roleId));
        })
        .catch((e) => {
            console.log(e);
        });
}

export const addRightToRole = (roleId: number, rightId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/roles/${roleId}/rights/${rightId}`)
        .then((response: AxiosResponse) => {
            dispatch(getIndividualRoleInformation(roleId));
        })
        .catch((e) => {
            console.log(e);
        });
}

interface CreateRoleResponseSucces {
    status: 'success',
    data: number
}

interface CreateRoleResponseError {
    status: 'error',
    message: string
}

type CreateRoleResponse = CreateRoleResponseSucces | CreateRoleResponseError

export const createRole = (name: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/roles`, {
        name: name
    })
        .then((response: AxiosResponse<CreateRoleResponse>) => {
            if (response.data.status === 'success' ) {
                dispatch(setCreatedRoleId(response.data.data));
            } else if (response.data.status === 'error') {
                dispatch(setCreateRoleError(response.data.message))
            }
        })
        .catch((e) => {
            console.log(e);
        });
}