import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

type CheckoutStatus = 'error'|'succes'|'pending'|null;

// Define a type for the slice state
interface ProductState {
    products: CheckoutProduct[],
    errorMessage: string|null,
    status: CheckoutStatus,
    deleteOrderStatus: CheckoutStatus
}

export interface CheckoutProduct {
    productId: number,
    quantity: number
}

// Define the initial state using that type
const initialState: ProductState = {
    products: [],
    errorMessage: null,
    status: null,
    deleteOrderStatus: null
}

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        addProduct: (state, action: PayloadAction<number>) => {
            let found = false;
            let newProducts = state.products.map((product) => {
                if (product.productId === action.payload) {
                    found = true;
                    return {
                        ...product,
                        quantity: ++product.quantity
                    }
                }
                return { ...product }
            })
            if (!found) {
                newProducts = state.products.concat({
                    productId: action.payload,
                    quantity: 1
                });
            }
            state.products = newProducts;
            state.errorMessage = null;
        },
        removeProduct: (state, action: PayloadAction<number>) => {
            let newProducts: CheckoutProduct[] = [];
            state.products.forEach((product) => {
                if (product.productId === action.payload) {
                    if (product.quantity > 1) {
                        newProducts = newProducts.concat({
                            ...product,
                            quantity: --product.quantity
                        })
                    }
                } else {
                    newProducts = newProducts.concat(product);
                }
            })
            state.products = newProducts;
            state.errorMessage = null;
        },
        clearCheckout: () => {
            return initialState;
        },
        setErrorMessage: (state, action: PayloadAction<string|null>) => {
            state.errorMessage = action.payload
            state.status = 'error';
        },
        setStatus: (state, action: PayloadAction<CheckoutStatus>) => {
            state.status = action.payload
        },
        setDeleteOrderStatus: (state, action: PayloadAction<CheckoutStatus>) => {
            state.deleteOrderStatus = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { addProduct, removeProduct, clearCheckout, setErrorMessage, setStatus, setDeleteOrderStatus} = checkoutSlice.actions
export default checkoutSlice.reducer

interface CreateOrderResponseSuccess {
    status: 'success',
    data: number
}

interface createOrderResponseError {
    status: 'error',
    message: string
}

type createOrderResponse = CreateOrderResponseSuccess | createOrderResponseError

export const createOrder = (userId: number, products: CheckoutProduct[]): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch(setErrorMessage(null));
    dispatch(setStatus('pending'))
    axios.post(`/api/orders`, {
        user_id: userId,
        products: products.map((product) => {
            return {
                product_id: product.productId, quantity: product.quantity
            }
        })
    })
        .then((response: AxiosResponse<createOrderResponse>) => {
            if (response.data.status === 'error') {
                dispatch(setErrorMessage(response.data.message));
            } else {
                dispatch(setStatus('succes'))
            }
        })
        .catch((e) => {
            console.log(e);
        })
}

export const deleteOrder = (orderId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch(setDeleteOrderStatus('pending'))
    axios.delete(`/api/orders/` + orderId)
        .then((response: AxiosResponse<createOrderResponse>) => {
            if (response.data.status === 'error') {
                dispatch(setDeleteOrderStatus('error'));
            } else {
                dispatch(setDeleteOrderStatus('succes'))
            }
        })
        .catch((e) => {
            console.log(e);
        })
}