import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

// Define a type for the slice state
interface RevenueSalesState {
    revenueSales: RevenueSale[],
}

export interface RevenueSale {
    payment_method: string,
    amount: number
}

// Define the initial state using that type
const initialState: RevenueSalesState = {
    revenueSales: []
}

export const revenueSalesSlice = createSlice({
    name: 'revenue_sales',
    initialState,
    reducers: {
        setRevenueSales: (state, action: PayloadAction<RevenueSale[]>) => {
            state.revenueSales = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setRevenueSales } = revenueSalesSlice.actions
export default revenueSalesSlice.reducer

export const searchRevenueSales = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/sales/revenue')
        .then((response: AxiosResponse<{ status: string, data: RevenueSale[] }>) => {
            dispatch(setRevenueSales(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}