import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Autocomplete } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../hooks';
import { searchUsers } from '../../../../features/users/UsersSlice';
import { clearCheckout, createOrder } from '../../../../features/products/CheckoutSlice';
import axios, {AxiosResponse} from "axios";

interface AddRightDialogInterface {
    open: boolean,
    onClose: () => void
}

interface AutoCompleteOption {
    id: number,
    label: string
}

const CreateorderDialog = ({ open, onClose }: AddRightDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<AutoCompleteOption | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<AutoCompleteOption[]>([]);
    const users = useAppSelector((state) => state.users.users)
    const checkout = useAppSelector((state) => state.checkout);
    const [error, setError] = useState<string | null>(null);
    const refContainer = useRef<HTMLInputElement|null>(null);
    const [profileImageSrc, setProfileImaegSrc] = useState<string|null>(null);

    useEffect(() => {
        dispatch(searchUsers({ id: null, name: null, email: null, is_active: true }));
    }, [dispatch]);

    const getProfileImage = (userId: number) => {
        axios.get(`/api/users/image/${userId}`, { responseType:"blob" })
            .then((response: AxiosResponse) => {
                var reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = function() {
                    const src = reader.result;
                    if (src instanceof ArrayBuffer) {
                        return;
                    }
                    setProfileImaegSrc(src)
                }
            });
    }

    useEffect(() => {
        switch (checkout.status) {
            case 'succes':
                dispatch(clearCheckout())
                onClose();
                setInputValue('');
                setValue(null);
                setError(null);
                break;
            case 'error': 
                setError(checkout.errorMessage);
                break;
            case 'pending': 
                break;
            default:
                setError(null);
                break;
        }
    }, [checkout.errorMessage, checkout.status, dispatch, onClose])

    useEffect(() => {
        const newUsers = users.map((user) => {
            return {
                id: user.id,
                label: `(${user.short_name}) - ${user.name}`
            };
        });
        setOptions(newUsers);
    }, [users]);

    const onCreateOrder = (() => {
        if (!value) {
            setError('Please select an user and payment method.')
            return;
        }
        dispatch(createOrder(value.id, checkout.products));
    });

    const handleKeyDown = ({event, inputProp}: { event: any, inputProp: any }) => {
        if (inputProp == null && event.key === 'Enter') {
            onCreateOrder();
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Bestelling aanmaken</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Autocomplete
                    sx={{ marginTop: 1 }}
                    value={value}
                    onChange={(event: any, newValue: AutoCompleteOption | null) => {
                        setValue(newValue);
                        if (newValue?.id) {
                            getProfileImage(newValue.id);
                        } else {
                            setProfileImaegSrc(null);
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={options}
                    autoSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            label="Gebruiker"
                            inputRef={refContainer}
                            onKeyDown={(event) => handleKeyDown({event: event, inputProp: params.inputProps['aria-controls']})}
                        />
                    )}
                />
                {profileImageSrc!=='data:' &&  profileImageSrc &&
                    <img alt="profile_image" src={profileImageSrc} height="200px" />
                }
                {error &&
                    <Alert sx={{ marginTop: 1 }} severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button onClick={onClose}>Annuleren</Button>
                <Button onClick={onCreateOrder} disabled={checkout.status === 'pending'}>Bestelling bevestigen</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateorderDialog;