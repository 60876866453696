import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks';
import { getAllRoles } from '../../../../features/roles/RolesSlice';
import { addRoleToUser } from '../../../../features/users/UsersSlice';

interface AddRightDialogInterface {
    open: boolean,
    onClose: () => void
}


const AddRoleDialog = ({ open, onClose }: AddRightDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<string[]>([]);
    const roles = useAppSelector((state) => state.roles.roles)
    const user = useAppSelector((state) => state.users.user)
    useEffect(() => {
        dispatch(getAllRoles());
    }, [dispatch]);

    useEffect(() => {
        const newRoles = roles.map((role) => {
            return role.name;
        });
        setOptions(newRoles);
    }, [roles])

    const onAddRole = (() => {
        const role = roles.find((role) => role.name === value);
        if (!role || !user) {
            return;
        }
        dispatch(addRoleToUser(user.id, role.id));
        onClose();
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add role</DialogTitle>
            <DialogContent sx={{width: '500px'}}>
                <Autocomplete
                    sx={{marginTop: '10px'}}
                    value={value}
                    onChange={(event: any, newValue: string | null) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={options}
                    renderInput={(params) => <TextField {...params} label="Role" />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onAddRole}>Add Role</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddRoleDialog;