import { styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../features/authentication/userSlice';
import { useAppSelector } from '../hooks';

const SyledLoginForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '300px',
    },
    '& .MuiButtonBase-root': {
        margin: theme.spacing(2),
    },
}));

interface LoginFormInterface {
    onClose: () => void
}

const LoginForm = ({onClose}: LoginFormInterface): JSX.Element => {
    const user = useAppSelector((state) => state.user)
    const dispatch = useDispatch();
    // create state variables for each input
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(loginUser(email, password));
    };

    useEffect(() => {
        if (user.state === 'success') {
            onClose();
        }
    }, [onClose, user.state])

    return (
        <SyledLoginForm onSubmit={handleSubmit}>
            <TextField
                label="Email"
                variant="filled"
                type="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <TextField
                label="Password"
                variant="filled"
                type="password"
                required
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <div>
                <Button variant="contained" onClick={onClose}>
          Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
          Login
                </Button>
            </div>
        </SyledLoginForm>
    );
};

export default LoginForm;