import React, { useState } from 'react';
import {Alert, Box, Button, Grid, TextField} from '@mui/material';
import { SearchUserParams, searchUsers } from '../../features/users/UsersSlice';
import { useDispatch } from 'react-redux';
import UsersSearchResultTable from './UsersSearchResultTable/UsersSearchResultTable';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router';
import CreateUserDialog from './CreateUserDialog/CreateUserDialog';
import axios from "axios";


const UserSearchPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();
    const [searchUsersParams, setSearchUsersParams] = useState<SearchUserParams>({
        id: null,
        name: null,
        email: null,
        is_active: null
    });
    const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    const [isImportingUsers, setIsImportingUsers] = useState(false);
    const dispatch = useDispatch();

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('user_search')) {
        navigate('/');
    }

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchUsersParams({
            ...searchUsersParams,
            name: event.target.value || null
        });
    };

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchUsersParams({
            ...searchUsersParams,
            email: event.target.value || null
        });
    };

    const onIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchUsersParams({
            ...searchUsersParams,
            id: parseInt(event.target.value) || null
        });
    };

    const onSearch = (): void => {
        dispatch(searchUsers(searchUsersParams))
    }

    const onCreateProductClick = (): void => {
        setIsCreateUserDialogOpen(true);
    }

    const onUserRefresh = (): void => {
        setIsImportingUsers(true);
        axios.post('/api/users/congressus_import')
            .then(() => {
                document.location.reload();
            })
            .catch((): void => {
                setIsImportingUsers(false)
                setErrorMessage('Something went wrong while importing the users.')
            })
    }

    return (
        <div>
            <CreateUserDialog 
                open={isCreateUserDialogOpen}
                onClose={() => setIsCreateUserDialogOpen(false)}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Id"
                        type="number"
                        value={searchUsersParams.id || ''}
                        onChange={onIdChange}
                        fullWidth
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSearch();
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Naam"
                        value={searchUsersParams.name || ''}
                        onChange={onNameChange}
                        fullWidth
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSearch();
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="E-mail"
                        value={searchUsersParams.email || ''}
                        onChange={onEmailChange}
                        fullWidth
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSearch();
                            }
                        }}
                    />
                </Grid>
            </Grid>
            {errorMessage &&
                <Alert sx={{ marginTop: '10px' }} severity="error">{errorMessage}</Alert>
            }
            {isImportingUsers &&
                <Alert sx={{ marginTop: '10px' }} severity="info">Importing users from Congressus...</Alert>
            }
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {hasRight('user_refresh') &&
                    <Button
                        sx={{ mt: 3, ml: 1 }}
                        onClick={onUserRefresh}
                        variant="contained"
                    >
                        Import users from congressus
                    </Button>
                }
                {hasRight('user_create') &&
                    <Button
                        sx={{ mt: 3, ml: 1 }}
                        onClick={onCreateProductClick}
                        variant="contained"
                    >
                        Create User
                    </Button>
                }
                <Button
                    variant="contained"
                    onClick={onSearch}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Search
                </Button>
            </Box>
            <hr />
            <UsersSearchResultTable />
        </div>
    );
};

export default UserSearchPage;